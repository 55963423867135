/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */

import { createContext, useContext } from "react";

export const SDKClientContext = createContext(null);

/**
 * Get the SDK Client
 * @returns {SDKClient} the SDKClient
 */
export function useSDKClient() {
  return useContext(SDKClientContext);
}

/*
function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}
*/

/**
 * SDK Client to interact with backend
 */
export class SDKClient {
  user = undefined;
  company_id = undefined;
  fleet_id = undefined;
  host = undefined;

  get isConfigured() {
    return !!this.host && !!this.user && !!this.company_id && !!this.fleet_id;
  }

  async fetch(url, options = {}) {
    const token = await this.user.getIdToken();
    let headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (options.headers) {
      headers = { ...headers, ...options.headers };
    }
    return new Promise((resolve, reject) => {
      fetch(url, {
        credentials: "same-origin",
        mode: "cors",
        headers: headers,
        ...options,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          resolve(response);
        })
        .catch(reject);
    });
  }

  async getWaypoints() {
    const response = await this.fetch(
      `${this.host}/v2/company/${this.company_id}/fleets/${this.fleet_id}/waypoints`
    );
    return response.json();
  }

  async getOrders() {
    const response = await this.fetch(
      `${this.host}/v2/company/${this.company_id}/fleets/${this.fleet_id}/orders`
    );
    return response.json();
  }
  async getOrder(id) {
    const response = await this.fetch(
      `${this.host}/v2/company/${this.company_id}/fleets/${this.fleet_id}/orders/${id}`
    );
    return response.json();
  }

  /**
   * Create a new order and get offers in return
   */
  async createOrder(data) {
    const payload = {
      pickup: {
        waypoint_id: parseInt(data.from),
      },
      dropoff: {
        waypoint_id: parseInt(data.to),
      },
      number_of_passengers: parseInt(data.passengers),
    };

    const utcWhen = new Date(data.when).toISOString();

    if (data.whenType === "arrive_before") {
      payload.dropoff.time = utcWhen;
    } else if (data.whenType === "leave_at") {
      payload.pickup.time = utcWhen;
    } else {
      // Default is leaving NOW
      payload.pickup.time = new Date().toISOString();
    }

    const response = await this.fetch(
      `${this.host}/v2/company/${this.company_id}/fleets/${this.fleet_id}/orders`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    return response.json();
  }

  async selectOffer(order_id, quote_id, assignment_id) {
    const payload = {
      quote_id: quote_id,
      assignment_id: assignment_id,
    };
    const response = await this.fetch(
      `${this.host}/v2/company/${this.company_id}/fleets/${this.fleet_id}/orders/${order_id}`,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    return response.json();
  }

  async cancelOrder(order_id) {
    const response = await this.fetch(
      `${this.host}/v2/company/${this.company_id}/fleets/${this.fleet_id}/orders/${order_id}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
}
