/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import {
  IonApp,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/react";

export default function LoadingPage({ title, children }) {
  return (
    <IonApp data-testid="loading-page">
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title ?? "Loading"}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {children ? children : <div className="ion-padding">Loading...</div>}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
