/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */

import { createContext, useContext } from "react";

export const AppConfigContext = createContext(null);

/**
 * @typedef RemoteAppConfig
 * @type {object}
 * @property {string} host SDK host
 * @property {string} [survey] url to a survey
 * @property {number} company_id the company_id
 * @property {number} fleet_id the fleet_id
 */

/**
 * Get the Remote App Config values
 * @returns {RemoteAppConfig} the remote app config
 */
export function useAppConfig() {
  return useContext(AppConfigContext);
}
