/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSDKClient } from "../utils/SDKClient";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { format } from "date-fns";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonButton,
  IonItemDivider,
  IonInput,
  useIonLoading,
  useIonToast,
  useIonModal,
  IonIcon,
} from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TripDuration } from "../utils/TripDuration";
import { getAnalytics, logEvent } from "../utils/firebase";
import MessageCard from "../components/MessageCard";
import { map } from "ionicons/icons";
import { RouteMapModal } from "../components/RouteMapModal";
import { useAppConfig } from "../utils/useAppConfig";

// Function to provide iso style format but in local time
function getLocaleDateTime(date) {
  return format(date, "yyyy-MM-dd'T'HH:mm");
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useURLQuery() {
  return new URLSearchParams(useLocation().search);
}

function simpleIDCompare(a, b) {
  return String(a) === String(b);
}

function validateForm(values) {
  const errors = {};
  if (!values.from) {
    errors.from = "Required field";
  }
  if (!values.to) {
    errors.to = "Required field";
  }
  if (values.from && values.to && values.from === values.to) {
    errors.to = 'Cannot be the same as "from"';
  }
  if (!values.passengers || values.passengers < 1) {
    errors.passengers = "Choose number of passengers";
  }
  if (values.passengers && values.passengers > 6) {
    errors.passengers = "Max 6 passengers";
  }
  return errors;
}

function ValidationError({ children }) {
  return <div style={{ color: "var(--ion-color-danger)" }}>{children}</div>;
}

export function FindJourneyPage() {
  const intl = useIntl();
  const client = useSDKClient();
  const queryClient = useQueryClient();
  const query = useURLQuery();
  const history = useHistory();
  const appConfig = useAppConfig();

  //const { pathname } = useLocation();
  const [form, setForm] = useState({
    from: "",
    to: "",
    passengers: 1,
    whenType: "now",
    when: getLocaleDateTime(new Date()),
    now: getLocaleDateTime(new Date()),
    ...Object.fromEntries(query),
  });

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();

  const [validationErrors, setValidationErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [presentRouteMapModal, dismissRouteMapModal] = useIonModal(
    RouteMapModal,
    {
      dismiss: () => {
        dismissRouteMapModal();
      },
    }
  );

  const waypoints = useQuery(
    "waypoints",
    async () => {
      return await client.getWaypoints();
    },
    {
      staleTime: 1000 * 60 * 60 * 1, // 1t
    }
  );

  const orderMutation = useMutation(async (data) => {
    return await client.createOrder(data);
  });

  const resetOrderMutation = orderMutation?.reset;

  useEffect(() => {
    setValidationErrors(validateForm(form));
    resetOrderMutation?.();
  }, [form, resetOrderMutation]);

  if (waypoints.isLoading) {
    return <LoadingPage />;
  }

  if (waypoints.isError) {
    return (
      <ErrorPage message="Error fetching data. Please refresh to try again" />
    );
  }

  const sortedWaypoints = waypoints.data
    ? waypoints.data.sort((a, b) =>
        a?.name?.localeCompare(b?.name, intl.locale)
      )
    : null;

  const outOfService =
    window.location.hostname === "ktp.web.appliedautonomy.no";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Find journey</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          data-testid="journey-form"
          onSubmit={(e) => {
            e.preventDefault();
            resetOrderMutation?.();
            setTouched({
              from: true,
              to: true,
              passengers: true,
              when: true,
              whenType: true,
            });
            const errors = validateForm(form);
            setValidationErrors(errors);
            if (Object.keys(errors).length === 0) {
              // Add form values to query
              /*history.replace(
                `${pathname}?${new URLSearchParams(form).toString()}`
              );*/
              orderMutation.mutate(form);
              logEvent(getAnalytics(), "order_search", form);
            } else {
              // TODO: show a message maybe?
            }
          }}
        >
          <IonList>
            <IonItem>
              <IonLabel position="stacked">From</IonLabel>
              <IonSelect
                data-testid="field-from"
                compareWith={simpleIDCompare}
                name="from"
                interface="popover"
                value={form.from}
                placeholder="Select a stop"
                onIonChange={(e) => {
                  const value = e.detail.value;
                  setTouched((s) => ({ ...s, from: true }));
                  setForm((s) => ({ ...s, from: value }));
                }}
              >
                {sortedWaypoints &&
                  sortedWaypoints.map((waypoint) => (
                    <IonSelectOption key={waypoint.id} value={waypoint.id}>
                      {waypoint.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
              {touched.from && validationErrors.from && (
                <ValidationError>{validationErrors.from}</ValidationError>
              )}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">To</IonLabel>
              <IonSelect
                data-testid="field-to"
                compareWith={simpleIDCompare}
                name="to"
                interface="popover"
                value={form.to}
                placeholder="Select a stop"
                onIonChange={(e) => {
                  const value = e.detail.value;
                  setTouched((s) => ({ ...s, to: true }));
                  setForm((s) => ({ ...s, to: value }));
                }}
              >
                {sortedWaypoints &&
                  sortedWaypoints.map((waypoint) => (
                    <IonSelectOption key={waypoint.id} value={waypoint.id}>
                      {waypoint.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
              {touched.to && validationErrors.to && (
                <ValidationError>{validationErrors.to}</ValidationError>
              )}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Passengers</IonLabel>
              <IonInput
                data-testid="field-passengers"
                type="number"
                name="passengers"
                value={form.passengers}
                min={1}
                max={6}
                onIonChange={(e) => {
                  const value = e.detail.value;
                  setTouched((s) => ({ ...s, passengers: true }));
                  setForm((s) => ({ ...s, passengers: value }));
                }}
              />
              {touched.passengers && validationErrors.passengers && (
                <ValidationError>{validationErrors.passengers}</ValidationError>
              )}
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">When</IonLabel>
              <IonSelect
                name="whenType"
                interface="popover"
                value={form.whenType}
                placeholder="Select a stop"
                onIonChange={(e) => {
                  const value = e.detail.value;
                  setForm((s) => ({ ...s, whenType: value }));
                  setValidationErrors(validateForm(form));
                }}
              >
                <IonSelectOption value="now">Now</IonSelectOption>
                <IonSelectOption value="leave_at">Leave at</IonSelectOption>
                <IonSelectOption value="arrive_before">
                  Arrive before
                </IonSelectOption>
              </IonSelect>
              {touched.whenType && validationErrors.whenType && (
                <ValidationError>{validationErrors.whenType}</ValidationError>
              )}
            </IonItem>
            {form.whenType !== "now" && (
              <IonItem>
                <IonLabel position="stacked">Time</IonLabel>
                <IonDatetime
                  name="when"
                  min={form.now}
                  displayFormat="HH:mm"
                  value={form.when}
                  onIonChange={(e) => {
                    const value = e.detail.value;
                    setForm((s) => ({ ...s, when: value }));
                    setValidationErrors(validateForm(form));
                  }}
                ></IonDatetime>
                {touched.when && validationErrors.when && (
                  <ValidationError>{validationErrors.when}</ValidationError>
                )}
              </IonItem>
            )}
            <div
              className="ion-padding"
              style={{ display: "flex", gap: "1rem" }}
            >
              <IonButton
                color="secondary"
                fill="outline"
                onClick={presentRouteMapModal}
              >
                <IonIcon slot="start" icon={map} />
                Map
              </IonButton>
              <IonButton
                style={{ flex: "1" }}
                data-testid="find-button"
                expand="block"
                type="submit"
                disabled={outOfService}
              >
                Find
              </IonButton>
            </div>
          </IonList>
        </form>
        {outOfService && (
          <>
            <MessageCard color="success">
              <h1>Pilot project finished</h1>
              <p>
                The bus is not in service because the pilot project is finished.
              </p>
            </MessageCard>
            <div
              className="ion-padding"
              style={{ textAlign: "center", marginTop: "1rem" }}
            >
              <div
                style={{
                  marginBottom: "0.5rem",
                  fontSize: "0.9rem",
                  color: "var(--ion-color-medium)",
                }}
              >
                If you have used the bus previously, please remember to take the
                anonymous passenger survey
              </div>
              <IonButton
                target="_blank"
                href={appConfig?.survey}
                color="secondary"
                size="small"
              >
                Go to survey
              </IonButton>
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                  fontSize: "0.9rem",
                  color: "var(--ion-color-medium)",
                }}
              >
                Results from the project will be published on the project
                website{" "}
                <a
                  target="_blank"
                  href="https://www.sohjoalastmile.eu/"
                  rel="noreferrer"
                >
                  https://www.sohjoalastmile.eu/
                </a>{" "}
                after the end of the project in early 2022. Thanks to everyone
                who used the bus or helped with the pilot in some other way!
              </div>
            </div>
          </>
        )}
        {orderMutation.isLoading && (
          <div className="ion-padding">Searching...</div>
        )}
        {orderMutation.isError && (
          <MessageCard color="danger">
            Could not find any options. <br />
            Try again with a different time or stops
          </MessageCard>
        )}
        {!orderMutation.isLoading &&
          orderMutation.data &&
          (orderMutation.data?.offers?.length > 0 ? (
            <OrderAlternativesList
              onItemClick={async (offer) => {
                try {
                  const { order_id, id } = orderMutation.data;
                  presentLoading({ message: "Selecting..." });
                  const data = await client.selectOffer(
                    order_id,
                    id,
                    offer.assignment_id
                  );
                  queryClient.setQueryData(["order", data.id], data, {
                    staleTime: 1000 * 60, // 60s
                  });
                  setTimeout(() => {
                    dismissLoading();
                  }, 100);
                  logEvent(getAnalytics(), "order_offer_selected", {
                    order_id,
                    id,
                    assignment_id: offer.assignment_id,
                  });
                  presentToast({
                    message: "Order created",
                    color: "success",
                    duration: 2000,
                  });
                  history.push(`/orders/${data.id}`);
                  resetOrderMutation?.();
                } catch (e) {
                  setTimeout(() => {
                    dismissLoading();
                  }, 100);
                  presentToast({
                    message: "Error when creating order",
                    color: "danger",
                    duration: 2000,
                  });
                }
              }}
              offers={orderMutation.data.offers}
            />
          ) : (
            <MessageCard color="warning">
              Could not find any options. <br />
              Try again with a different time or stops
            </MessageCard>
          ))}
      </IonContent>
    </IonPage>
  );
}

function groupOffersByDay(offers) {
  return offers.reduce((acc, item) => {
    const day = format(new Date(item.pickup_time), "yyyy-MM-dd");
    if (acc[day]) {
      acc[day].push(item);
    } else {
      acc[day] = [item];
    }
    return acc;
  }, {});
}

function OrderAlternativesList({ offers, onItemClick }) {
  const groupedOffers = groupOffersByDay(offers);
  return (
    <IonList data-testid="offers-list">
      {groupedOffers &&
        Object.keys(groupedOffers)
          .sort((a, b) => b.localeCompare(a))
          .map((groupKey) => (
            <React.Fragment key={groupKey}>
              <IonItemDivider>
                <IonLabel>
                  <FormattedDate value={new Date(groupKey)} />
                </IonLabel>
              </IonItemDivider>
              {groupedOffers[groupKey].map((offer) => (
                <OfferItem
                  key={offer.assignment_id}
                  offer={offer}
                  onClick={onItemClick}
                />
              ))}
            </React.Fragment>
          ))}
    </IonList>
  );
}

function OfferItem({ offer, onClick }) {
  return (
    <IonItem>
      <IonLabel>
        <div>
          <div style={{ display: "flex", gap: "0.4rem" }}>
            <div>
              <div style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                <FormattedTime value={offer.pickup_time} />
              </div>
              <div style={{ fontSize: "0.8em", opacity: "0.6" }}>pick-up</div>
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                opacity: "0.6",
                //alignSelf: "center",
              }}
            >
              -
            </div>
            <div>
              <div style={{ fontSize: "1.4em", fontWeight: "bold" }}>
                <FormattedTime value={offer.dropoff_time} />
              </div>
              <div style={{ fontSize: "0.8em", opacity: "0.6" }}>drop-off</div>
            </div>
          </div>
          <div style={{ marginTop: "0.5em", fontSize: "0.8em" }}>
            <TripDuration
              pickup={offer.pickup_time}
              dropoff={offer.dropoff_time}
            />{" "}
            duration
          </div>
        </div>
      </IonLabel>
      <IonButton
        slot="end"
        onClick={() => {
          onClick(offer);
        }}
        color="secondary"
      >
        Select
      </IonButton>
    </IonItem>
  );
}
/*
function ShortOfferItem({ offer, onClick }) {
  return (
    <IonItem>
      <IonLabel>
        <div>
          <div style={{ fontSize: "1.4em", fontWeight: "bold" }}>
            <FormattedTime value={offer.pickup_time} /> -{" "}
            <FormattedTime value={offer.dropoff_time} />
          </div>
          <TripDuration
            pickup={offer.pickup_time}
            dropoff={offer.dropoff_time}
          />
        </div>
      </IonLabel>
      <IonButton
        slot="end"
        onClick={() => {
          onClick(offer);
        }}
        color="secondary"
      >
        Select
      </IonButton>
    </IonItem>
  );
}
*/
