/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

/*
export const languages = {
  no: "Norwegian",
  sv: "Swedish",
  en: "English",
};

export const locales = {
  no: "Norwegian",
  en: "English",
};
*/

export const flattenMessages = (nestedMessages, prefix = "") => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "string") {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
};

export function getMessagesForLanguage(language = "") {
  let lang = null;

  if (["no", "nb", "nn"].includes(language.substr(0, 2))) {
    lang = "no";
  } else if (language.substr(0, 2) === "sv") {
    lang = "sv";
  } else {
    return Promise.resolve({});
  }
  return new Promise((resolve, reject) => {
    import(`./${lang}.json`)
      .then((module) => {
        resolve(flattenMessages(module.default));
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function ExtendedIntlProvider({
  loading,
  locale,
  language,
  timezone,
  children,
}) {
  const [messages, setMessages] = useState(null);
  useEffect(() => {
    if (language) {
      getMessagesForLanguage(language)
        .then((data) => {
          setMessages(data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [language]);
  if (language && messages === null) {
    return loading;
  }
  return (
    <IntlProvider
      messages={messages}
      timeZone={timezone}
      locale={locale}
      defaultLocale="nb-NO"
    >
      {children}
    </IntlProvider>
  );
}
