/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import { IonCard, IonCardContent } from "@ionic/react";

export default function MessageCard({ color, children }) {
  return (
    <IonCard color={color}>
      <IonCardContent>{children}</IonCardContent>
    </IonCard>
  );
}
