/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import React from "react";

/*

These styles are in index.css

.routemap-stop {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
}
.routemap-stop-label {
  fill: #1d1d1d;
}
@media (prefers-color-scheme: dark) {
  .routemap-stop-label {
    fill: white;
  }
  .routemap-stop {
    stroke-width: 0px;
  }
}


*/

export function RouteMap(props) {
  return (
    <svg
      width="340"
      height="517"
      viewBox="0 0 340 517"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49 467L49 328L277 328L277 467M49 328L277 328"
        stroke="#1D8DDD"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89 328L89 47L246 47L246 328M89 47L246 47"
        stroke="#1D8DDD"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g
        id="labels"
        fontSize="14"
        fill="white"
        xmlSpace="preserve"
        letterSpacing="0em"
        className="routemap-stop-label"
      >
        <text id="KTP-Resepsjonen">
          <tspan x="118.31" y="27.4766">
            KTP-Resepsjonen
          </tspan>
        </text>
        <text id="P-hus S&#195;&#184;rporten">
          <tspan x="228.238" y="495.477">
            P-hus S&#xf8;rporten
          </tspan>
        </text>
        <text id="Bygg 72">
          <tspan x="127.589" y="305.477">
            Bygg 72
          </tspan>
        </text>
        <text id="Bygg 60">
          <tspan x="267" y="250.477">
            Bygg 60
          </tspan>
        </text>
        <text id="Bygg 9">
          <tspan x="267" y="99.4766">
            Bygg 9
          </tspan>
        </text>
        <text id="Bygg 90">
          <tspan x="69" y="380.477">
            Bygg 90
          </tspan>
        </text>
        <text id="Tess">
          <tspan x="42.4902" y="268.477">
            Tess
          </tspan>
        </text>
        <text id="K-tech">
          <tspan x="29.748" y="189.785">
            K-tech
          </tspan>
        </text>
        <text id="Kantina">
          <tspan x="24.8672" y="90.4766">
            Kantina
          </tspan>
        </text>
        <text id="Bygg 127 - Midgard">
          <tspan x="22.7568" y="496.477">
            Bygg 127 - Midgard
          </tspan>
        </text>
      </g>
      <circle
        className="routemap-stop"
        cx="49"
        cy="375"
        r="12.5"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M50 369C50 368.448 49.5523 368 49 368C48.4477 368 48 368.448 48 369H50ZM48.2929 381.707C48.6834 382.098 49.3166 382.098 49.7071 381.707L56.0711 375.343C56.4616 374.953 56.4616 374.319 56.0711 373.929C55.6805 373.538 55.0474 373.538 54.6569 373.929L49 379.586L43.3431 373.929C42.9526 373.538 42.3195 373.538 41.9289 373.929C41.5384 374.319 41.5384 374.953 41.9289 375.343L48.2929 381.707ZM48 369V381H50V369H48Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="152"
        cy="328"
        r="12.5"
        transform="rotate(90 152 328)"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M158 329C158.552 329 159 328.552 159 328C159 327.448 158.552 327 158 327V329ZM145.293 327.293C144.902 327.683 144.902 328.317 145.293 328.707L151.657 335.071C152.047 335.462 152.68 335.462 153.071 335.071C153.462 334.681 153.462 334.047 153.071 333.657L147.414 328L153.071 322.343C153.462 321.953 153.462 321.32 153.071 320.929C152.68 320.538 152.047 320.538 151.657 320.929L145.293 327.293ZM158 327H146V329H158V327Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="89"
        cy="265"
        r="12.5"
        transform="rotate(180 89 265)"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M88 271C88 271.552 88.4477 272 89 272C89.5523 272 90 271.552 90 271H88ZM89.7071 258.293C89.3166 257.902 88.6834 257.902 88.2929 258.293L81.9289 264.657C81.5384 265.047 81.5384 265.681 81.9289 266.071C82.3194 266.462 82.9526 266.462 83.3431 266.071L89 260.414L94.6568 266.071C95.0474 266.462 95.6805 266.462 96.071 266.071C96.4616 265.681 96.4616 265.047 96.071 264.657L89.7071 258.293ZM90 271V259H88V271H90Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="89"
        cy="187"
        r="12.5"
        transform="rotate(180 89 187)"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M88 193C88 193.552 88.4477 194 89 194C89.5523 194 90 193.552 90 193H88ZM89.7071 180.293C89.3166 179.902 88.6834 179.902 88.2929 180.293L81.9289 186.657C81.5384 187.047 81.5384 187.681 81.9289 188.071C82.3195 188.462 82.9526 188.462 83.3431 188.071L89 182.414L94.6569 188.071C95.0474 188.462 95.6805 188.462 96.0711 188.071C96.4616 187.681 96.4616 187.047 96.0711 186.657L89.7071 180.293ZM90 193V181H88V193H90Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="89"
        cy="87"
        r="12.5"
        transform="rotate(180 89 87)"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M88 93C88 93.5523 88.4477 94 89 94C89.5523 94 90 93.5523 90 93H88ZM89.7071 80.2929C89.3166 79.9024 88.6834 79.9024 88.2929 80.2929L81.9289 86.6569C81.5384 87.0474 81.5384 87.6805 81.9289 88.0711C82.3195 88.4616 82.9526 88.4616 83.3431 88.0711L89 82.4142L94.6569 88.0711C95.0474 88.4616 95.6805 88.4616 96.0711 88.0711C96.4616 87.6805 96.4616 87.0474 96.0711 86.6569L89.7071 80.2929ZM90 93V81H88V93H90Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="171"
        cy="46.9998"
        r="12.5"
        transform="rotate(-90 171 46.9998)"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M165 45.9998C164.448 45.9998 164 46.4475 164 46.9998C164 47.5521 164.448 47.9998 165 47.9998V45.9998ZM177.707 47.7069C178.098 47.3164 178.098 46.6832 177.707 46.2927L171.343 39.9287C170.953 39.5382 170.32 39.5382 169.929 39.9287C169.538 40.3193 169.538 40.9524 169.929 41.343L175.586 46.9998L169.929 52.6567C169.538 53.0472 169.538 53.6804 169.929 54.0709C170.32 54.4614 170.953 54.4614 171.343 54.0709L177.707 47.7069ZM165 47.9998H177V45.9998H165V47.9998Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="246"
        cy="95"
        r="12.5"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M247 89C247 88.4477 246.552 88 246 88C245.448 88 245 88.4477 245 89H247ZM245.293 101.707C245.683 102.098 246.317 102.098 246.707 101.707L253.071 95.3431C253.462 94.9526 253.462 94.3195 253.071 93.9289C252.681 93.5384 252.047 93.5384 251.657 93.9289L246 99.5858L240.343 93.9289C239.953 93.5384 239.319 93.5384 238.929 93.9289C238.538 94.3195 238.538 94.9526 238.929 95.3431L245.293 101.707ZM245 89V101H247V89H245Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="246"
        cy="247"
        r="12.5"
        fill="white"
        stroke="#D0D0D0"
      />
      <path
        d="M247 241C247 240.448 246.552 240 246 240C245.448 240 245 240.448 245 241H247ZM245.293 253.707C245.683 254.098 246.317 254.098 246.707 253.707L253.071 247.343C253.462 246.953 253.462 246.319 253.071 245.929C252.681 245.538 252.047 245.538 251.657 245.929L246 251.586L240.343 245.929C239.953 245.538 239.319 245.538 238.929 245.929C238.538 246.319 238.538 246.953 238.929 247.343L245.293 253.707ZM245 241V253H247V241H245Z"
        fill="#848484"
      />
      <circle
        className="routemap-stop"
        cx="277"
        cy="467"
        r="12.5"
        fill="white"
        stroke="#D0D0D0"
      />
      <circle
        className="routemap-stop"
        cx="49"
        cy="467"
        r="12.5"
        fill="white"
        stroke="#D0D0D0"
      />
    </svg>
  );
}
