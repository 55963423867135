/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */

import { differenceInMinutes, startOfMinute } from "date-fns";

export function TripDuration({ pickup, dropoff }) {
  const diff = Math.abs(
    differenceInMinutes(
      startOfMinute(new Date(pickup)),
      startOfMinute(new Date(dropoff))
    )
  );

  return `${diff} min`;
}
