/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import { useEffect, useMemo, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import {
  getAnalytics as firebaseGetAnalytics,
  logEvent as firebaseLogEvent,
} from "@firebase/analytics";

export const logEvent =
  process.env.NODE_ENV === "production"
    ? firebaseLogEvent
    : (...args) => console.log("Analytics", args);

export const getAnalytics =
  process.env.NODE_ENV === "production"
    ? firebaseGetAnalytics
    : (...args) => {};

/**
 * Get the user state from firebase auth
 * @param {Auth} auth the firebase auth instance
 * @returns user state
 */
export function useFirebaseAuthState(auth) {
  const [state, setState] = useState(() => ({
    value: auth.currentUser,
    loading: !auth.currentUser,
    error: false,
  }));

  useEffect(() => {
    return onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          setState((s) => ({
            ...s,
            value: user,
            loading: false,
            error: undefined,
          }));
        } else {
          setState((s) => ({
            ...s,
            value: undefined,
            loading: false,
            error: undefined,
          }));
        }
      },
      (e) => {
        setState((s) => ({
          ...s,
          value: undefined,
          loading: false,
          error: e,
        }));
      }
    );
  }, [auth]);

  return useMemo(
    () => [state.value, state.loading, state.error],
    [state.value, state.loading, state.error]
  );
}
