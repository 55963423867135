/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import {
  IonApp,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from "@ionic/react";
import MessageCard from "../components/MessageCard";

export default function ErrorPage({ title, children, message }) {
  return (
    <IonApp>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{title ?? "Error"}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {children ? (
            children
          ) : (
            <div className="ion-padding">
              <MessageCard color="danger">
                {message ?? "Something went wrong"}
              </MessageCard>
            </div>
          )}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
