/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { refresh } from "ionicons/icons";
import { useRouteMatch } from "react-router-dom";
import { useSDKClient } from "../utils/SDKClient";
import { useQuery, useQueryClient } from "react-query";
import { usePageActive } from "../utils/usePageActive";
import { format } from "date-fns";
import { FormattedDate, FormattedTime } from "react-intl";
import React from "react";
import { TripDuration } from "../utils/TripDuration";
import { OrderStatusBadge } from "../components/OrderStatusBadge";
import MessageCard from "../components/MessageCard";
import { shouldShowOriginalTimes } from "../utils/delay";

function useOrders(options = {}) {
  const client = useSDKClient();

  return useQuery(
    "orders",
    async () => {
      return await client.getOrders();
    },
    {
      staleTime: 1000 * 10, // Stale after 10 seconds
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 20, // Auto fetch new data every 20s
      ...options,
    }
  );
}

function groupOrdersByDay(orders) {
  if (!orders) return {};
  return orders.reduce((acc, item) => {
    try {
      const day = format(new Date(item?.pickup?.time), "yyyy-MM-dd");
      if (acc[day]) {
        acc[day].push(item);
      } else {
        acc[day] = [item];
      }
    } catch (e) {
      console.error("Pickup time error", item, e);
    }
    return acc;
  }, {});
}

export function OrdersPage() {
  const pageActive = usePageActive();
  const queryClient = useQueryClient();

  const match = useRouteMatch();
  const orders = useOrders({
    enabled: pageActive,
    onSuccess: (orders) => {
      for (let order of orders) {
        queryClient.setQueryData(["order", order.id], order, {
          staleTime:
            order.lifecycle === "executing" ||
            order.lifecycle === "planned" ||
            order.lifecycle === "transit"
              ? 1000 * 5 // 5s
              : 1000 * 60, // 60s
        });
      }
    },
  });
  const groupedOrders = orders.data
    ? groupOrdersByDay(
        orders.data.filter((item) => item?.pickup?.time && item?.dropoff?.time)
      )
    : null;
  const groupedOrdersKeys = groupedOrders ? Object.keys(groupedOrders) : null;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Your orders</IonTitle>
          <IonButtons slot="end">
            <IonButton
              data-testid="refresh-button"
              onClick={() => {
                orders.refetch();
              }}
            >
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {orders.isError && (
          <MessageCard color="danger">Error fetching orders</MessageCard>
        )}
        {(orders.isFetching || orders.isLoading) && (
          <div className="ion-padding">Loading...</div>
        )}
        {!orders.isLoading &&
        orders.isError ? null : groupedOrdersKeys?.length > 0 ? (
          <IonList data-testid="order-list">
            {groupedOrdersKeys
              .sort((a, b) => b?.localeCompare(a))
              .map((groupKey) => (
                <React.Fragment key={groupKey}>
                  <IonItemDivider>
                    <IonLabel>
                      <FormattedDate value={new Date(groupKey)} />
                    </IonLabel>
                  </IonItemDivider>
                  {groupedOrders[groupKey]
                    .sort((a, b) =>
                      b?.pickup?.time?.localeCompare(a.pickup.time)
                    )
                    .map((order) => (
                      <IonItem
                        detail={true}
                        key={order.id}
                        routerLink={`${match.url}/${order.id}`}
                      >
                        <IonLabel
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.3rem",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.3em",
                              }}
                            >
                              <FormattedTime value={order.pickup.time} /> -{" "}
                              <FormattedTime value={order.dropoff.time} />
                            </span>{" "}
                            (
                            <TripDuration
                              pickup={order.pickup.time}
                              dropoff={order.dropoff.time}
                            />
                            )
                          </div>
                          {shouldShowOriginalTimes(
                            order.pickup,
                            order.dropoff
                          ) && (
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.3em",
                                opacity: "0.5",
                                textDecoration: "line-through",
                              }}
                            >
                              <FormattedTime
                                value={order.pickup.original_time}
                              />{" "}
                              -{" "}
                              <FormattedTime
                                value={order.dropoff.original_time}
                              />
                            </div>
                          )}
                          <div>
                            <div
                              style={{
                                display: "inline-block",
                                marginRight: "0.5rem",
                              }}
                            >
                              {order.pickup.waypoint_name}
                            </div>
                            <div style={{ display: "inline-block" }}>
                              <ArrowRight
                                style={{ height: "1rem", width: "1rem" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {order.dropoff.waypoint_name}
                            </div>
                          </div>
                        </IonLabel>
                        <OrderStatusBadge slot="end" status={order.lifecycle} />
                      </IonItem>
                    ))}
                </React.Fragment>
              ))}
          </IonList>
        ) : (
          !orders.isLoading &&
          !orders.isFetching && (
            <div className="ion-padding">No orders found</div>
          )
        )}
      </IonContent>
    </IonPage>
  );
}

function ArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
}
