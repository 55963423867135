/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import { Redirect, Route } from "react-router-dom";

import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ExtendedIntlProvider } from "./localization";
import InitialLoading from "./InitialLoading";

import { person, search, list } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { initializeApp, getApp } from "firebase/app";
import { initializePerformance } from "firebase/performance";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { ProfilePage } from "./pages/ProfilePage";
import { OrdersPage } from "./pages/OrdersPage";
import { SignInPage } from "./pages/SignInPage";
import { FindJourneyPage } from "./pages/FindJourneyPage";

import { useFirebaseAuthState } from "./utils/firebase";
import { getAnalytics } from "./utils/firebase";

import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";
import { useEffect, useState } from "react";
import { SDKClientContext, SDKClient } from "./utils/SDKClient";
import { QueryClient, QueryClientProvider } from "react-query";
import PageNotFound from "./pages/PageNotFound";
import { OrderDetailsPage } from "./pages/OrderDetailsPage";

import { persistQueryClient } from "react-query/persistQueryClient-experimental";

import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { ReactQueryDevtools } from "react-query/devtools";
import ErrorPage from "./pages/ErrorPage";
import { AppConfigContext } from "./utils/useAppConfig";

const sdkClient = new SDKClient();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //refetchOnWindowFocus: false,
      //refetchOnMount: false,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
  throttleTime: 5000,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

/**
 * Fetch firebase config and initialize the app
 */
export default function FirebaseRoot() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [firebaseConfig, setFirebaseConfig] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    fetch("/__/firebase/init.json")
      .then(async (response) => {
        const config = await response.json();

        // Set appId manually when it can't find it automatically
        if (!config.appId && process.env.NODE_ENV !== "production") {
          config.appId = "1:4725642892:web:2139e9b137a838b7c18da9";
        }
        setFirebaseConfig(config);
      })
      .catch((e) => {
        setError(e);
        console.error("Could not get firebase config", e);
      });
  }, []);

  useEffect(() => {
    if (firebaseConfig) {
      let firebaseApp = null;
      try {
        firebaseApp = getApp();
      } catch (e) {
        firebaseApp = initializeApp(firebaseConfig);
        // Only turn this on in cloud
        if (process.env.NODE_ENV === "production") {
          initializePerformance(firebaseApp);
          getAnalytics(); // Think this has to be called to initialize analytics
        } else {
          const auth = getAuth();
          // If not already configured
          if (!auth.emulatorConfig) {
            connectAuthEmulator(
              auth,
              `http://${window.location.hostname}:9099`
            );
          }
        }
      }
      setIsInitialized(true);
    }
  }, [firebaseConfig]);

  if (error) {
    return <ErrorPage message="Could not get config" />;
  }
  if (!isInitialized) {
    return <InitialLoading />;
  }
  return <App />;
}

export function App() {
  const [user, authLoading] = useFirebaseAuthState(getAuth());
  const [SDKConfigured, setSDKConfigured] = useState(false);
  const [error, setError] = useState();
  const [appConfig, setAppConfig] = useState();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const remoteConfig = getRemoteConfig();
      fetchAndActivate(remoteConfig)
        .then((d) => {
          const app_config = JSON.parse(
            getValue(remoteConfig, "app_config").asString()
          );
          sdkClient.host = app_config.host;
          sdkClient.company_id = app_config.company_id;
          sdkClient.fleet_id = app_config.fleet_id;
          setSDKConfigured(sdkClient.isConfigured);
          setAppConfig(app_config);
        })
        .catch((err) => {
          console.error("REMOTE CONFIG ERROR", err);
          setError(err);
        });
    } else {
      sdkClient.host = `http://${window.location.hostname}:4019`;
      sdkClient.company_id = 1;
      sdkClient.fleet_id = 1;
      setSDKConfigured(sdkClient.isConfigured);
      setAppConfig({
        host: `http://${window.location.hostname}:4019`,
        company_id: 1,
        fleet_id: 1,
        survey: "https://appliedautonomy.no",
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      sdkClient.user = user;
      setSDKConfigured(sdkClient.isConfigured);
    }
  }, [user]);

  if (error) {
    return <ErrorPage message="Could not get remote config" />;
  }

  if (!authLoading && !user) {
    return (
      <IonApp>
        <SignInPage />
      </IonApp>
    );
  }

  if (authLoading || !SDKConfigured) {
    return <InitialLoading />;
  }

  return (
    <AppConfigContext.Provider value={appConfig}>
      <QueryClientProvider client={queryClient}>
        <SDKClientContext.Provider value={sdkClient}>
          <ExtendedIntlProvider
            timezone="Europe/Oslo"
            //language="nb-NO"
            locale="nb-NO"
            loading={<InitialLoading />}
          >
            <IonApp>
              <IonReactRouter>
                <IonTabs>
                  <IonRouterOutlet>
                    <Route path="/profile">
                      <ProfilePage />
                    </Route>
                    <Route path="/orders/:id">
                      <OrderDetailsPage />
                    </Route>
                    <Route exact path="/orders">
                      <OrdersPage />
                    </Route>
                    <Route path="/find">
                      <FindJourneyPage />
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/find" />
                    </Route>
                    <Route>
                      <PageNotFound />
                    </Route>
                  </IonRouterOutlet>
                  <IonTabBar slot="bottom">
                    <IonTabButton tab="find" href="/find">
                      <IonIcon icon={search} />
                      <IonLabel>Find journey</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="orders" href="/orders">
                      <IonIcon icon={list} />
                      <IonLabel>Orders</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="profile" href="/profile">
                      <IonIcon icon={person} />
                      <IonLabel>Profile</IonLabel>
                    </IonTabButton>
                  </IonTabBar>
                </IonTabs>
              </IonReactRouter>
            </IonApp>
          </ExtendedIntlProvider>
        </SDKClientContext.Provider>
        {process.env.NODE_ENV !== "production" && (
          <ReactQueryDevtools position="bottom-right" />
        )}
      </QueryClientProvider>
    </AppConfigContext.Provider>
  );
}
