/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import {
  IonApp,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
} from "@ionic/react";

export default function PageNotFound() {
  return (
    <IonApp>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Not found</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" style={{ textAlign: "center" }}>
          Page not found
          <div style={{ marginTop: "2rem" }}>
            <IonButton
              onClick={() => {
                window.location = "/";
              }}
            >
              Go home
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
