/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
} from "@ionic/react";
import { getAuth } from "firebase/auth";
import { useFirebaseAuthState } from "../utils/firebase";

export function ProfilePage() {
  const auth = getAuth();
  const [user] = useFirebaseAuthState(auth);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding" style={{ textAlign: "center" }}>
          You are logged in with this phone number:
          <div style={{ fontSize: "2.5rem" }}>{user?.phoneNumber}</div>
          <IonButton
            style={{ marginTop: "3rem" }}
            onClick={() => {
              auth.signOut();
            }}
          >
            Sign out
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
