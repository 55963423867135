/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import {
  IonLabel,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonButtons,
  IonBackButton,
  IonButton,
  IonInput,
} from "@ionic/react";
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  getAuth,
} from "firebase/auth";
import { useEffect, useState, useRef, useCallback } from "react";
import { parsePhoneNumber } from "../utils/parsePhoneNumber";

export function SignInPage() {
  const auth = getAuth();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState();
  const [error, setError] = useState();

  const recaptchaContainer = useRef(null);
  const codeInput = useRef(null);

  const setupRecaptcha = useCallback(() => {
    if (auth && recaptchaContainer.current) {
      const newRecaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainer.current,
        {
          size: "normal",
          callback: (data) => {
            setRecaptchaVerified(true);
          },
          "expired-callback": () => {
            setRecaptchaVerified(false);
          },
        },
        auth
      );
      newRecaptchaVerifier
        .render()
        .then(() => {})
        .catch((e) => {
          console.error("Error rendering recaptcha", e);
        });
      setRecaptchaVerifier(newRecaptchaVerifier);
      return () => {
        newRecaptchaVerifier.clear();
        setRecaptchaVerifier(null);
      };
    } else {
      console.error("DOM or auth not ready", auth, recaptchaContainer);
    }
  }, [auth]);

  useEffect(() => {
    setupRecaptcha();
  }, [setupRecaptcha]);

  const signIn = () => {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

    signInWithPhoneNumber(auth, parsedPhoneNumber, recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);

        // Set focus in the code input
        setTimeout(() => {
          codeInput?.current?.setFocus();
        }, 500);
        //console.log(confirmationResult);
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.error("NOT SENT", error);
      });
  };

  const verifyCode = () => {
    setError(null);
    confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        //const user = result.user;
        //console.log("Signed in", result, user);
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.error("Error with code", error);
        setError(error);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign in</IonTitle>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {confirmationResult ? (
          <div className="ion-padding ion-text-center">
            <div style={{ marginBottom: "1rem" }}>
              A code has been sent to {phoneNumber}
            </div>
            <IonItem>
              <IonLabel position="stacked">Code</IonLabel>
              <IonInput
                ref={codeInput}
                value={code}
                onIonChange={(e) => {
                  const value = e.detail.value;
                  setCode(value);
                }}
                autofocus={true}
                placeholder="Your code"
                autocomplete="one-time-code"
                clearInput={true}
                required={true}
                style={{
                  fontSize: "2.5rem",
                }}
              />
            </IonItem>
            {error && (
              <div style={{ color: "var(--ion-color-danger)" }}>
                Verification code is not correct. Try again
              </div>
            )}
            <IonButton
              disabled={!code}
              size="large"
              expand="block"
              style={{
                width: "100%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              onClick={verifyCode}
            >
              Verify code
            </IonButton>
            <IonButton
              fill="outline"
              color="medium"
              expand="block"
              onClick={() => {
                window.location.reload();
              }}
            >
              Cancel
            </IonButton>
            <div style={{ marginTop: "3rem" }}>
              Did you not receive a verification code?
              <div style={{ marginTop: "0.5rem" }}>
                <IonButton
                  fill="outline"
                  color="medium"
                  size="small"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Go back to try again
                </IonButton>
              </div>
            </div>
          </div>
        ) : (
          <div className="ion-padding ion-text-center">
            <div>
              Provide your phone number to sign in.
              <br />A code will be sent to verify you
            </div>
            <IonItem style={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <IonLabel position="stacked">Phone number</IonLabel>
              <IonInput
                style={{
                  fontSize: "2.5rem",
                }}
                value={phoneNumber}
                type="tel"
                autocomplete="tel"
                autofocus={true}
                placeholder="Your phone number"
                clearInput={true}
                inputMode="tel"
                onIonChange={(e) => {
                  const value = e.detail.value;
                  setPhoneNumber(value);
                }}
              />
            </IonItem>
            <div
              style={{ display: "inline-block" }}
              ref={recaptchaContainer}
            ></div>
            <IonButton
              size="large"
              expand="block"
              disabled={!recaptchaVerified || !phoneNumber}
              onClick={signIn}
            >
              Sign in
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

/*
function ResendCodeButton({ initialSecondsLeft = 30, ...props }) {
  const [secondsLeft, setSecondsLeft] = useState(initialSecondsLeft);
  useEffect(() => {
    if (secondsLeft > 0) {
      const id = setTimeout(() => {
        console.log("TRIGGER");
        setSecondsLeft((s) => (s > 0 ? s - 1 : 0));
      }, 1000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [secondsLeft]);

  return (
    <IonButton {...props} disabled={secondsLeft > 0}>
      {secondsLeft > 0 ? `Resend code in ${secondsLeft} sec` : "Resend code"}
    </IonButton>
  );
}
*/
