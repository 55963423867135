/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonFooter,
} from "@ionic/react";
import { RouteMap } from "./RouteMap";

export function RouteMapModal({ dismiss }) {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Route map</IonTitle>
          <IonButtons slot="end">
            <IonButton data-testid="refresh-button" onClick={dismiss}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <RouteMap style={{ width: "100%", height: "auto" }} />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={dismiss} color="primary" expand="block">
            Close
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
}
