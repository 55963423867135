import { useState } from "react";
import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";

/**
 * Figure out if the current page is active according to ionic
 * @param {Boolean} initialState true or false initial state
 * @returns {Boolean} if the page is active or not
 */
export function usePageActive(initialState = false) {
  const [pageActive, setPageActive] = useState(initialState);

  useIonViewWillEnter(() => {
    setPageActive(true);
  });

  useIonViewWillLeave(() => {
    setPageActive(false);
  });

  return pageActive;
}
