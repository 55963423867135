/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import { IonApp, IonPage, IonHeader, IonToolbar, IonTitle } from "@ionic/react";

export default function InitialLoading() {
  return (
    <IonApp>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Loading...</IonTitle>
          </IonToolbar>
        </IonHeader>
      </IonPage>
    </IonApp>
  );
}
