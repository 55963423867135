/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
export function parsePhoneNumber(phoneNumber) {
  if (phoneNumber.startsWith("0047")) {
    return `+47${phoneNumber.substr(4)}`;
  }
  if (phoneNumber.startsWith("+")) {
    return phoneNumber;
  }
  return `+47${phoneNumber}`;
}
