/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonButton,
  useIonAlert,
  useIonLoading,
  useIonToast,
  IonIcon,
} from "@ionic/react";
import { useRouteMatch } from "react-router-dom";
import { useSDKClient } from "../utils/SDKClient";
import { useMutation, useQuery } from "react-query";
import { usePageActive } from "../utils/usePageActive";
import { FormattedPlural, FormattedTime } from "react-intl";
import { TripDuration } from "../utils/TripDuration";
import { OrderStatusBadge } from "../components/OrderStatusBadge";
import MessageCard from "../components/MessageCard";
import { refresh } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAppConfig } from "../utils/useAppConfig";
import { datetimesAreDifferent } from "../utils/delay";

function OriginalTime({ time, delay }) {
  return (
    <div
      style={{
        fontWeight: "bold",
        fontSize: "1.5em",
        opacity: "0.5",
        textDecoration: "line-through",
      }}
    >
      <FormattedTime value={time} />
    </div>
  );
}

function CurrentTime({ time, delay, different }) {
  let color = undefined;

  if (different) {
    if (delay > 0) {
      color = "var(--ion-color-danger)";
    } else {
      color = "var(--ion-color-success)";
    }
  }
  return (
    <div
      style={{
        fontWeight: "bold",
        fontSize: "1.5em",
        color,
      }}
    >
      <FormattedTime value={time} />
    </div>
  );
}

export function OrderDetailsPage() {
  const { params } = useRouteMatch();
  const appConfig = useAppConfig();

  const id = parseInt(params.id);
  const pageActive = usePageActive();

  const [presentAlert, dismissAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();
  const client = useSDKClient();

  const [refetchInterval, setRefetchInterval] = useState(undefined);

  const {
    data: order,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    ["order", id],
    async () => {
      return await client.getOrder(id);
    },
    {
      enabled: pageActive,
      staleTime: refetchInterval ?? 1000 * 60, // 60s
      refetchInterval: refetchInterval,
    }
  );
  const cancelMutation = useMutation(async (order_id) => {
    return await client.cancelOrder(order_id);
  });

  useEffect(() => {
    // Only activate interval refetch when order is executing or planned
    if (
      order?.lifecycle &&
      (order.lifecycle === "executing" ||
        order.lifecycle === "planned" ||
        order.lifecycle === "transit")
    ) {
      setRefetchInterval(1000 * 10); // 10s
    }
  }, [order?.lifecycle]);

  const vehicle = order
    ? order.allocations.find((a) => a.type === "vehicle")
    : null;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/orders" />
          </IonButtons>
          <IonTitle>Order</IonTitle>
          <IonButtons slot="end">
            <IonButton
              data-testid="refresh-button"
              onClick={() => {
                refetch();
              }}
            >
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isError && (
          <MessageCard color="danger">Error fetching order details</MessageCard>
        )}
        {(isLoading || isFetching) && (
          <div className="ion-padding">Loading...</div>
        )}
        {order && (
          <div
            className="ion-padding"
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {order.lifecycle === "transit" && (
              <MessageCard color="success">
                The vehicle is on it's way to you
              </MessageCard>
            )}
            <div style={{ fontSize: "2rem" }}>
              <OrderStatusBadge status={order.lifecycle} />
            </div>

            <div>
              <div>
                <div>pick-up</div>
                {datetimesAreDifferent(
                  order.pickup.time,
                  order.pickup.original_time
                ) && (
                  <OriginalTime
                    time={order.pickup.original_time}
                    delay={order.pickup.delay}
                  />
                )}
                <CurrentTime
                  time={order.pickup.time}
                  delay={order.pickup.delay}
                  different={datetimesAreDifferent(
                    order.pickup.time,
                    order.pickup.original_time
                  )}
                />

                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {order.pickup.waypoint_name}
                </div>
              </div>
              <div style={{ margin: "1rem auto" }}>
                <ArrowDown style={{ height: "1.8rem", width: "1.8rem" }} />
              </div>
              <div>
                <div>drop-off</div>
                {datetimesAreDifferent(
                  order.dropoff.time,
                  order.dropoff.original_time
                ) && (
                  <OriginalTime
                    time={order.dropoff.original_time}
                    delay={order.dropoff.delay}
                  />
                )}
                <CurrentTime
                  time={order.dropoff.time}
                  delay={order.dropoff.delay}
                  different={datetimesAreDifferent(
                    order.dropoff.time,
                    order.dropoff.original_time
                  )}
                />
                <div
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  {order.dropoff.waypoint_name}
                </div>
              </div>
            </div>
            <div>
              <TripDuration
                pickup={order.pickup.time}
                dropoff={order.dropoff.time}
              />{" "}
              trip duration
            </div>

            <div>
              {order.number_of_passengers}{" "}
              <FormattedPlural
                value={order.number_of_passengers}
                one="passenger"
                other="passengers"
              />
            </div>
            {vehicle && <div>Vehicle: {vehicle.name}</div>}
            {["draft", "planned"].includes(order.lifecycle) && (
              <div>
                <IonButton
                  onClick={() => {
                    presentAlert({
                      header: "Confirm cancellation",
                      message: "Are you sure you want to cancel the order?",
                      cssClass: "confirmAlert",
                      buttons: [
                        {
                          role: "cancel",
                          text: "No",
                        },
                        {
                          text: "Cancel order",
                          cssClass: "danger",
                          handler: async (d) => {
                            dismissAlert();
                            presentLoading({ message: "Cancelling..." });
                            try {
                              await cancelMutation.mutateAsync(order.id);
                              setTimeout(() => {
                                dismissLoading();
                              }, 100);
                              presentToast({
                                message: "Order cancelled",
                                color: "success",
                                duration: 2000,
                              });
                              refetch();
                            } catch (error) {
                              setTimeout(() => {
                                dismissLoading();
                              }, 100);
                              presentToast({
                                message: "Error while cancelling order",
                                color: "danger",
                                duration: 2000,
                              });
                            }
                          },
                        },
                      ],
                    });
                  }}
                  color="danger"
                  fill="outline"
                >
                  Cancel order
                </IonButton>
              </div>
            )}
            {appConfig?.survey && (
              <div style={{ marginTop: "2rem" }}>
                <div
                  style={{
                    marginBottom: "0.5rem",
                    fontSize: "0.9rem",
                    color: "var(--ion-color-medium)",
                  }}
                >
                  Take the anonymous passenger survey for a chance to win a free
                  1-month pass from Brakar. <br />
                  <br />
                  Once you have completed the survey, send an SMS to{" "}
                  <a href="tel:+4793214409">93214409</a> to enter the draw.
                  <br />
                  <br />
                </div>
                <IonButton
                  target="_blank"
                  href={appConfig?.survey}
                  color="medium"
                  fill="outline"
                  size="small"
                >
                  Go to survey
                </IonButton>
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}
