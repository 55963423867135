/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import { IonBadge } from "@ionic/react";
import React from "react";

export function OrderStatusBadge({ status, ...props }) {
  const COLORS = {
    done: "primary",
    cancelled: "danger",
    executing: "success",
    draft: "light",
    planned: "secondary",
  };
  return (
    <IonBadge color={COLORS[status]} {...props}>
      {status}
    </IonBadge>
  );
}
