/*
 * Copyright 2019 Applied Autonomy AS. All rights reserved.
 * See: LICENSE.md in the root directory for details on how to license the code or product.
 */
import { format } from "date-fns";
export function shouldShowOriginalTimes(pickup, dropoff) {
  return (
    datetimesAreDifferent(pickup.time, pickup.original_time) ||
    datetimesAreDifferent(dropoff.time, dropoff.original_time)
  );
}

export function datetimesAreDifferent(a, b) {
  return (
    format(new Date(a), "y-MM-dd'T'HH:mm") !==
    format(new Date(b), "y-MM-dd'T'HH:mm")
  );
}
